import { Button, Skeleton, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useCallWhatsApp, useGetImage, useUpScaleImage } from '../api/Images.api';
import { useLocation } from 'react-router-dom';

const GetImage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { data: imageLink } = useGetImage(queryParams.get('contacted_br'), +queryParams.get('survey_id'), +queryParams.get('consumer_number'));
  const { mutate: upScaleImg } = useUpScaleImage();
  const { mutate: sendImage } = useCallWhatsApp();

  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    if (imageLink?.is_upscaled === true) {
      const prompt = queryParams.get('ques');
      const questions = prompt.split('-');

      const resultArray = questions.map((item) => {
        const [question, answerString] = item.split('__');

        return { question, answerString };
      });

      const answer = resultArray[0].answerString;
      let channel;
      if (answer === 'Yes') {
        channel = 'whatsapp';
      } else {
        channel = 'sms';
      }

      sendImage(
        { phone_number: +queryParams.get('consumer_number'), image_url: imageLink?.image_url, channel },
        {
          onSuccess: () => {
            console.log('finish');
          },
        }
      );
    }
  }, [imageLink]);

  const handleUpScaleImage = (value) => {
    setDisableBtn(true);
    const body = {
      index: value,
      msg_id: imageLink.message_id,
      msg_hash: imageLink.message_hash,
      trigger_id: imageLink.trigger_id,
      phone_number: +queryParams.get('consumer_number'),
      campaign_id: +queryParams.get('survey_id'),
      user_id: queryParams.get('contacted_br'),
    };

    upScaleImg(body, {
      onSuccess: () => {},
    });
  };

  return (
    <Space direction='vertical'>
      {imageLink?.type === 'end' ? (
        <>
          {imageLink?.is_upscaled === true ? (
            <Typography.Title level={4}>Image has been generated!</Typography.Title>
          ) : (
            <img src={`${imageLink?.image_url}`} alt='generated future' style={{ width: '450px', height: 'auto' }} />
          )}
          <Space align='center'>
            <Button disabled={disableBtn} type='primary' onClick={() => handleUpScaleImage(1)}>
              U1
            </Button>
            <Button disabled={disableBtn} type='primary' onClick={() => handleUpScaleImage(2)}>
              U2
            </Button>
            <Button disabled={disableBtn} type='primary' onClick={() => handleUpScaleImage(3)}>
              U3
            </Button>
            <Button disabled={disableBtn} type='primary' onClick={() => handleUpScaleImage(4)}>
              U4
            </Button>
          </Space>
        </>
      ) : (
        <Space align='center' direction='vertical'>
          <Skeleton.Image active={true} style={{ width: 300 }} />
          <Typography.Title level={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Generating Your Image. Please wait...
          </Typography.Title>
        </Space>
      )}
    </Space>
  );
};

export default GetImage;
