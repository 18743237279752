import { useEffect, useState } from 'react';
import { Button, Row, Skeleton, Space, Typography, Upload, message } from 'antd';
import { CameraFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useCreatePrompt } from '../api/Images.api';

const { Dragger } = Upload;

const Home = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { mutate: createPrompt, isLoading: creatingPrompt } = useCreatePrompt();

  const [fileList, setFileList] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const [imageSuccess, setImageSuccess] = useState(null);

  useEffect(() => {
    const prompt = queryParams.get('ques');
    console.log(prompt.includes('No'));
  }, []);

  const handleGenerateImage = () => {
    const prompt = queryParams.get('ques');

    let passionString;

    if (prompt.includes('ccad5e18-e910-43bf-8803-4710cb9a5781.jpg')) {
      passionString = 'music_lover';
    } else if (prompt.includes('8cd6140e-95bd-4e9f-80bb-b451c25affb5.jpg')) {
      passionString = 'entrepreneur';
    } else {
      passionString = 'photographer';
    }

    let channel;

    if (prompt.includes('Yes')) {
      channel = 'whatsapp';
    } else {
      channel = 'sms';
    }

    const body = {
      phone_number: +queryParams.get('consumer_number'),
      campaign_id: +queryParams.get('survey_id'),
      user_id: queryParams.get('contacted_br'),
      picurl: fileList[0].url,
      passion_point: passionString,
      channel,
    };

    createPrompt(body, {
      onSuccess: () => {
        setFileList(null);
        setApiCalled(true);
        console.log('finish');
      },
    });
  };

  const imageProps = {
    name: 'Users-Midjourney',
    multiple: true,
    action: `${process.env.REACT_APP_ecrm_ff_manager}/files`,
    headers: {
      Authorization: 'Bearer ' + queryParams.get('token'),
    },
    listType: 'picture',
    fileList: fileList,
    beforeUpload: (file) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/svg+xml') {
        message.error('Selected file is not an image');
      }
      return file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
    },
    onChange(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. Read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = process.env.REACT_APP_s3_cdn + file.response.file_name;
          file.image_name = file.response.file_name;
        }
        return file;
      });

      const uploadedFiles = fileList.filter((file) => file.status === 'done'); // Filter for successfully uploaded files
      if (uploadedFiles.length > 0) {
        setImageSuccess(uploadedFiles);
      }
      setFileList(fileList.filter((file) => !!file.status));
    },
  };

  return (
    <>
      <div className='custom-container'>
        {apiCalled ? (
          <Space align='center' direction='vertical'>
            <Skeleton.Image active={true} style={{ width: 300 }} />
            <Typography.Title level={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              Generating Your Image. Please wait...
            </Typography.Title>
          </Space>
        ) : (
          <>
            <Dragger {...imageProps} className='upload-list-inline'>
              <p className='ant-upload-drag-icon'>
                <CameraFilled />
              </p>
              <p>Click to take image of the consumer</p>
              <p className='ant-upload-hint'>Please open camera to capture consumer face. Strictly prohibited from uploading company data or other banned files.</p>
            </Dragger>
            <Row justify='center' style={{ marginTop: '2em' }}>
              <Button type='primary' size='large' onClick={handleGenerateImage} disabled={imageSuccess ? false : true} loading={creatingPrompt}>
                Upload Image
              </Button>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Home;
