import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import Home from './components/Home';
import GetImage from './components/GetImage';

import './App.css';

const queryClient = new QueryClient();

function App() {
  return (
    <div className='container'>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/get-image' element={<GetImage />} />
        </Routes>
      </QueryClientProvider>
    </div>
  );
}

export default App;
