import { useMutation, useQuery } from 'react-query';

import { ImageGeneration } from '../utils/Api';
import Notification from '../utils/Notification';

export const useCreatePrompt = () => useMutation((body) => ImageGeneration.post('/image/process', body, { error: Notification }));

export const useGetImage = (user_id, campaign_id, phone_number) => {
  return useQuery(['generated-image', user_id, campaign_id, phone_number], () => ImageGeneration.get(`/v1/api/get-image?user_id=${user_id}&campaign_id=${campaign_id}&phone_number=${phone_number}`), {
    select: ({ data }) => data,
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
  });
};

export const useUpScaleImage = () => useMutation((body) => ImageGeneration.post('/v1/api/upscale', body, { error: Notification }));

export const useCallWhatsApp = () => useMutation((body) => ImageGeneration.post('/v1/api/send', body, { error: Notification }));
